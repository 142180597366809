<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      v-model="sidebardetail"
      :no-header-close="true"
      bg-variant="light"
      right
      shadow
    >
      <template slots="title">
        <a-icon
          type="close-circle"
          class="text-secondary ml-3"
          @click="sidebardetail = false"
        />
        <strong
          class="float-right mr-3"
          style="
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          >{{ datalengkap.pemohon.nama }}</strong
        >
      </template>
      <!-- <template slots="header">
        <div class="row pl-1 pr-1 mb-2" style="margin-right: unset;">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-icon
              class="float-right"
              type="close"
              :style="'cursor: pointer;font-size: 20px;' + color"
              @mouseenter="color = 'color: #949494'"
              @mouseleave="color = 'color: #292929'"
              @click="sidebardetail = false"
            />
          </div>
        </div>
      </template> -->
      <div class="row pl-3" style="margin-right: unset;">
        <!-- <vue-custom-scrollbar
            class="scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHanle"
          >
          </vue-custom-scrollbar> -->
        <a-divider
          type="horizontal"
          style="margin-top: 10px; margin-bottom: 10px;"
        />
        <a-collapse accordion defaultActiveKey="3" style="width: 100%;">
          <a-collapse-panel key="1" header="Data kendaraan">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nomor BPKB:</strong
              ><span> {{ datalengkap.nomorbpkb }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Tanggal berlaku STNK:</strong
              ><span> {{ datalengkap.tglberlakustnk }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>BPKB atas nama:</strong
              ><span> {{ datalengkap.atasnamabpkb }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nama pemilik sekarang:</strong
              ><span> {{ datalengkap.namapemiliksekarang }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Alamat sekarang:</strong
              ><span> {{ datalengkap.alamatsekarang }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nomor polisi kendaraan:</strong
              ><span> {{ datalengkap.nopolisi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Merk kendaraan:</strong
              ><span> {{ datalengkap.merk }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Jenis kendaraan:</strong
              ><span> {{ datalengkap.jenis }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Tipe kendaraan:</strong
              ><span> {{ datalengkap.tipe }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Tahun pembuatan kendaraan:</strong
              ><span> {{ datalengkap.tahunpembuatan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Silinder kendaraan:</strong
              ><span> {{ datalengkap.isisilinder }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Jumlah roda kendaraan:</strong
              ><span> {{ datalengkap.jmlroda }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nomor rangka kendaraan:</strong
              ><span> {{ datalengkap.norangka }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nomor mesin kendaraan:</strong
              ><span> {{ datalengkap.nomesin }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Bahan bakar kendaraan:</strong
              ><span> {{ datalengkap.bahanbakar }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Warna kendaraan:</strong
              ><span> {{ datalengkap.warna }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Perlengkapan kendaraan:</strong
              ><span> {{ datalengkap.perlengkapan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Asuransi kendaraan:</strong
              ><span> {{ datalengkap.asuransi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Kondisi kendaraan:</strong
              ><span> {{ datalengkap.kondisikendaraan }}.</span>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="Data penilaian">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nilai pasar rata-rata:</strong
              ><span> {{ datalengkap.nilaipasarratarata }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Penyusutan:</strong
              ><span> {{ datalengkap.penyusutan }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nilai barang:</strong
              ><span> {{ datalengkap.nilaibarang }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Nilai taksasi:</strong
              ><span> {{ datalengkap.nilaitaksasi }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Persentase plafond kredit:</strong
              ><span> {{ datalengkap.presentaseplafondkredit }}.</span>
            </div>
            <a-divider
              type="horizontal"
              style="margin-top: 5px; margin-bottom: 5px;"
            />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Maksimal plafond kredit:</strong
              ><span> {{ datalengkap.maksimalplafondkredit }}.</span>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="Analisa">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Analisa:</strong><span> {{ datalengkap.analisa }}.</span>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </b-sidebar>
    <!-- <htmlpdf
      :columns="columns"
      :datatable="this.datalengkap.detail"
      :previewModal="false"
      :filename="this.datalengkap.faktur"
      ref="htmlpdf"
    /> -->
  </div>
</template>

<script>
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import * as lou from '@/services/data/lou'
// import htmlpdf from '@/components/lou/pdfprint.vue'
export default {
  components: {
    // vueCustomScrollbar,
    // htmlpdf,
  },
  name: 'right-bar',
  props: ['title'],
  data() {
    return {
      activeKey: ['1'],
      datalengkap: {
        pemohon: {
          nama: '',
        },
      },
      color: 'color: #292929;',
      color1: 'color: #292929;',
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      sidebardetail: false,
    }
  },
  computed: {},
  methods: {
    scrollHanle(evt) {
      // console.log(evt)
    },
    open(record) {
      // console.log('record.detail.length', record.detail.length)
      this.sidebardetail = this.datalengkap !== record ? true : !this.sidebardetail
      this.datalengkap = record
    },
    // printpdf() {
    //   this.$refs.htmlpdf.generateReport(
    //     this.datalengkap.detail,
    //     this.datalengkap.pelanggan,
    //   )
    // },
    close() {
      this.sidebardetail = false
    },
    numberFormat(value) {
      return lou.curency(value)
    },
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 320px;
}
</style>
